@font-face {
  font-family: Hafs;
  font-style: normal;
  font-weight: 400;
  src: url("../public/fonts/hafs/KfgqpcHafsUthmanicScriptRegular-1jGEe.ttf");
}

@font-face {
  font-family: Janna LT;
  font-style: normal;
  font-weight: 400;
  src: url("../public/fonts/janna-lt-bold/Janna\ LT\ Bold.ttf");
}

* {
  /* margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins, Arial, Helvetica, sans-serif; */
  scrollbar-width: none;
}

body {
  background-color: #f5f3f5;
  color: #f5f3f5;
}

h1 {
  font-family: "Rokkitt", serif;
  font-weight: 600;
  font-size: 50px;
  text-align: center;
}

.firebase-emulator-warning {
  opacity: 0.2;
}
ul {
  list-style-type: none;
}
/* Retro Style Circles */
.pattern1 {
  --s: 100px; /* control the size*/
  --c1: #1c208b;
  --c2: #61ba24;

  background: radial-gradient(
        var(--c2) 15%,
        #0000 17% 20%,
        var(--c2) 22% 25%,
        #0000 27% 30%,
        var(--c2) 32% 35%,
        #0000 37% 40%,
        var(--c2) 42% 45%,
        #0000 47% 50%,
        var(--c2) 52% 55%,
        var(--c1) 57%
      )
      calc(var(--s) / 2) 0 / var(--s) var(--s),
    repeating-conic-gradient(var(--c2) 0 25%, var(--c1) 0 50%) 0 0 /
      calc(2 * var(--s)) calc(2 * var(--s));
}

/* Wave Circles */
.pattern2 {
  --s: 160px; /* control the size*/
  --c1: #61ba24;
  --c2: #1c208b;

  --_g: var(--s) var(--s)
    radial-gradient(var(--c1) 17%, var(--c2) 18% 35%, #0000 36.5%);
  background: calc(var(--s) / -4) calc(var(--s) / -4) / var(--_g),
    calc(var(--s) / 4) calc(var(--s) / 4) / var(--_g),
    radial-gradient(var(--c2) 34%, var(--c1) 36% 68%, #0000 70%) 0 0 /
      calc(var(--s) / 2) calc(var(--s) / 2),
    repeating-linear-gradient(45deg, var(--c1) -12.5% 12.5%, var(--c2) 0 37.5%)
      0 0 / var(--s) var(--s);
}

/* Grid */
.pattern3 {
  --s2: 2px;

  background-image: linear-gradient(#61ba24 var(--s2), transparent var(--s2)),
    linear-gradient(to right, #61ba24 var(--s2), transparent var(--s2));
  background-size: 32px 32px;
  background-color: #1c208b;
}

/* Nested Squares */
.pattern4 {
  background: linear-gradient(
      45deg,
      #0000 calc(25% / 3),
      #61ba24 0 calc(50% / 3),
      #0000 0 calc(250% / 3),
      #61ba24 0 calc(275% / 3),
      #0000 0
    ),
    linear-gradient(
      45deg,
      #61ba24 calc(25% / 3),
      #0000 0 calc(50% / 3),
      #61ba24 0 25%,
      #0000 0 75%,
      #61ba24 0 calc(250% / 3),
      #0000 0 calc(275% / 3),
      #61ba24 0
    ),
    linear-gradient(
      -45deg,
      #0000 calc(25% / 3),
      #61ba24 0 calc(50% / 3),
      #0000 0 calc(250% / 3),
      #61ba24 0 calc(275% / 3),
      #0000 0
    ),
    linear-gradient(
        -45deg,
        #61ba24 calc(25% / 3),
        #0000 0 calc(50% / 3),
        #61ba24 0 25%,
        #0000 0 75%,
        #61ba24 0 calc(250% / 3),
        #0000 0 calc(275% / 3),
        #61ba24 0
      )
      #1c208b;
  background-size: 64px 64px;
  background-position: 0 0, 32px 32px;
}

/* Bars */
.pattern5 {
  background: linear-gradient(135deg, #0000 20.5%, #1c208b 0 29.5%, #0000 0) 0
      16px,
    linear-gradient(45deg, #0000 8%, #1c208b 0 17%, #0000 0 58%) 32px 0,
    linear-gradient(
      135deg,
      #0000 8%,
      #1c208b 0 17%,
      #0000 0 58%,
      #1c208b 0 67%,
      #0000 0
    ),
    linear-gradient(
      45deg,
      #0000 8%,
      #1c208b 0 17%,
      #0000 0 58%,
      #1c208b 0 67%,
      #0000 0 83%,
      #1c208b 0 92%,
      #0000 0
    ),
    #61ba24;
  background-size: 64px 64px;
}

/* Hexagons */
.pattern6 {
  background: conic-gradient(from 90deg at 2px 2px, #0000 25%, #1c208b 0) -1px -1px,
    linear-gradient(
      -45deg,
      #1c208b 15%,
      #61ba24 0 28%,
      #0000 0 72%,
      #61ba24 0 85%,
      #1c208b 0
    ),
    linear-gradient(
      45deg,
      #1c208b 15%,
      #61ba24 0 28%,
      #0000 0 72%,
      #61ba24 0 85%,
      #1c208b 0
    ),
    conic-gradient(from 90deg at 40% 40%, #1c208b 25%, #61ba24 0)
      calc(32px / -5) calc(32px / -5);
  background-size: 32px 32px;
}

/* Net 2 */
.pattern8 {
  background: radial-gradient(
        35.36% 35.36% at 100% 25%,
        #0000 66%,
        #61ba24 68% 70%,
        #0000 72%
      )
      32px 32px / calc(2 * 32px) calc(2 * 32px),
    radial-gradient(
        35.36% 35.36% at 0 75%,
        #0000 66%,
        #61ba24 68% 70%,
        #0000 72%
      )
      32px 32px / calc(2 * 32px) calc(2 * 32px),
    radial-gradient(
        35.36% 35.36% at 100% 25%,
        #0000 66%,
        #61ba24 68% 70%,
        #0000 72%
      )
      0 0 / calc(2 * 32px) calc(2 * 32px),
    radial-gradient(
        35.36% 35.36% at 0 75%,
        #0000 66%,
        #61ba24 68% 70%,
        #0000 72%
      )
      0 0 / calc(2 * 32px) calc(2 * 32px),
    repeating-conic-gradient(#1c208b 0 25%, #0000 0 50%) 0 0 / calc(2 * 32px)
      calc(2 * 32px),
    radial-gradient(#0000 66%, #61ba24 68% 70%, #0000 72%) 0 calc(32px / 2) /
      32px 32px #1c208b;
}

/* Wind Rose */
.pattern9 {
  background: conic-gradient(
      from -45deg at calc(100% / 3) calc(100% / 3),
      #1c208b 90deg,
      #0000 0
    ),
    conic-gradient(
      from -135deg at calc(100% / 3) calc(2 * 100% / 3),
      #1c208b 90deg,
      #61ba24 0 135deg,
      #0000 0
    ),
    conic-gradient(
      from 135deg at calc(2 * 100% / 3) calc(2 * 100% / 3),
      #1c208b 90deg,
      #61ba24 0 135deg,
      #0000 0
    ),
    conic-gradient(
      from 45deg at calc(2 * 100% / 3) calc(100% / 3),
      #1c208b 90deg,
      #61ba24 0 135deg,
      #0000 0,
      #1c208b 0 225deg,
      #61ba24 0
    );
  background-size: 64px 64px;
}

/* Polka Dot */
.pattern10 {
  /* autoprefixer: off */
  background: conic-gradient(#1c208b 25%, #0000 0) 0 0 / calc(2 * 32px)
      calc(32px / 9.5),
    conic-gradient(#1c208b 25%, #0000 0) 0 0 / calc(32px / 9.5) calc(2 * 32px),
    repeating-conic-gradient(#0000 0 25%, #1c208b 0 50%) 32px 0 / calc(2 * 32px)
      calc(2 * 32px),
    radial-gradient(50% 50%, #61ba24 98%, #1c208b) 0 0/32px 32px;
}

/* Carpet */
.pattern11 {
  background: conic-gradient(
        from -45deg,
        #1c208b 90deg,
        #0000 0 180deg,
        #363cd7 0 270deg,
        #0000 0
      )
      0 calc(32px / 2) / 32px 32px,
    conic-gradient(from 135deg at 50% 0, #1c208b 90deg, #363cd7 0) 0 0 /
      calc(2 * 32px) 32px;
}

/* Overlapping Circles */
.pattern12 {
  /* autoprefixer: off */
  --s12: 150px; /* control the size*/
  --c1: #61ba24;
  --c2: #1c208b;

  --_g: var(--c1) 0% 5%, var(--c2) 6% 15%, var(--c1) 16% 25%, var(--c2) 26% 35%,
    var(--c1) 36% 45%, var(--c2) 46% 55%, var(--c1) 56% 65%, var(--c2) 66% 75%,
    var(--c1) 76% 85%, var(--c2) 86% 95%, #0000 96%;
  background: radial-gradient(50% 50% at 100% 0, var(--_g)),
    radial-gradient(50% 50% at 0 100%, var(--_g)),
    radial-gradient(50% 50%, var(--_g)),
    radial-gradient(50% 50%, var(--_g)) calc(var(--s12) / 2)
      calc(var(--s12) / 2) var(--c1);
  background-size: var(--s12) var(--s12);
}

/* Rhombus and Rectangles */
.pattern13 {
  --s13: 170px; /* control the size*/
  --c1: #1c208b;
  --c2: #61ba24;

  --_c: #0000 75%, var(--c1) 0;
  --_g1: conic-gradient(at 20.71% 50%, var(--_c));
  --_g2: conic-gradient(at 50% 20.71%, var(--_c));
  background: var(--_g1) calc(var(--s13) / 6.828) calc(var(--s13) / 2),
    var(--_g1) calc(var(--s13) / -2.828) 0,
    conic-gradient(from 45deg at 29.29% 29.29%, var(--_c))
      calc(var(--s13) / -6.828) 0,
    var(--_g2) calc(var(--s13) / 2) calc(var(--s13) / -2.828),
    var(--_g2) 0 calc(var(--s13) / 6.828),
    conic-gradient(from -135deg at 29.29% 70.71%, var(--_c))
      calc(var(--s13) / -6.828) 0,
    var(--c2);
  background-size: var(--s13) var(--s13), var(--s13) var(--s13),
    calc(var(--s13) / 2) calc(var(--s13) / 2);
}

/* Candy Stripes */
.pattern15 {
  /* autoprefixer: off */
  background: radial-gradient(25% 25% at 25% 25%, #1c208b 99%, #0000 101%) 32px
      32px / calc(2 * 32px) calc(2 * 32px),
    radial-gradient(25% 25% at 25% 25%, #1c208b 99%, #0000 101%) 0 0 /
      calc(2 * 32px) calc(2 * 32px),
    radial-gradient(50% 50%, #363cd7 98%, #0000) 0 0/32px 32px,
    repeating-conic-gradient(#363cd7 0 25%, #1c208b 0 50%) calc(0.5 * 32px) 0 /
      calc(2 * 32px) 32px;
}

/* Z Shape */
.pattern14 {
  --s17: 106px; /* control the size*/
  --c1: #1c208b;
  --c2: #61ba24;

  background: conic-gradient(
        from 30deg at 87.5% 75%,
        var(--c1) 60deg,
        var(--c2) 0 120deg,
        #0000 0
      )
      0 calc(0.2165 * var(--s17)),
    conic-gradient(
      from -90deg at 50% 25%,
      var(--c2) 60deg,
      var(--c1) 0 180deg,
      #0000 0
    ),
    conic-gradient(
      from 90deg at 50% 75%,
      var(--c2) 120deg,
      var(--c1) 0 180deg,
      #0000 0
    ),
    conic-gradient(
      from -30deg at 87.5% 50%,
      var(--c2) 120deg,
      var(--c1) 0 240deg,
      #0000 0
    ),
    conic-gradient(
      from 90deg at 37.5% 50%,
      var(--c2) 120deg,
      var(--c1) 0 180deg,
      var(--c2) 0 240deg,
      var(--c1) 0
    );
  background-size: var(--s17) calc(0.866 * var(--s17));
}

/* Outline Ovals */
.pattern16 {
  --s20: 29px; /* control the size*/
  --c1: #61ba24;
  --c2: #1c208b;

  --g: #0000 calc(100% - var(--s20) / 3 - 1px),
    var(--c1) calc(100% - var(--s20) / 3) calc(100% - 1px), #0000;
  --r: calc(2.414 * var(--s20));
  --p: calc(1.414 * var(--s20));
  background: radial-gradient(var(--r) at 0 0, var(--g)) var(--r) var(--r),
    radial-gradient(var(--s20) at 0 0, var(--g)) var(--p) var(--p),
    radial-gradient(var(--r) at 0 100%, var(--g)) 0 var(--p),
    radial-gradient(var(--s20) at 0 100%, var(--g)) calc(-1 * var(--s20))
      var(--r),
    radial-gradient(var(--r) at 100% 0, var(--g)) var(--p) 0,
    radial-gradient(var(--s20) at 100% 0, var(--g)) var(--r)
      calc(-1 * var(--s20)),
    radial-gradient(var(--r) at 100% 100%, var(--g)) calc(-1 * var(--s20))
      calc(-1 * var(--s20)),
    radial-gradient(var(--s20) at 100% 100%, var(--g)) var(--c2);
  background-size: calc(2 * var(--r)) calc(2 * var(--r));
}

/* Outline Circles */
.pattern7 {
  --s20: 220px; /* control the size*/
  --c1: #61ba24;
  --c2: #1c208b;

  --_g: radial-gradient(
    #0000 60%,
    var(--c1) 61% 63%,
    #0000 64% 77%,
    var(--c1) 78% 80%,
    #0000 81%
  );
  --_c: , #0000 75%, var(--c2) 0;
  background: conic-gradient(at 12% 20% var(--_c)) calc(var(--s20) * 0.44)
      calc(0.9 * var(--s20)),
    conic-gradient(at 12% 20% var(--_c)) calc(var(--s20) * -0.06)
      calc(0.4 * var(--s20)),
    conic-gradient(at 20% 12% var(--_c)) calc(0.9 * var(--s20))
      calc(var(--s20) * 0.44),
    conic-gradient(at 20% 12% var(--_c)) calc(0.4 * var(--s20))
      calc(var(--s20) * -0.06),
    var(--_g), var(--_g) calc(var(--s20) / 2) calc(var(--s20) / 2) var(--c2);
  background-size: var(--s20) var(--s20);
}

@media only screen and (max-width: 576px) {
  * {
    cursor: none !important;
  }
}
