.maintenanceMode {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1c208b url('../../../public/images/backgrounds/mecca.png');
    background-size: cover;
    background-position: bottom;
    opacity: 0.8;
    filter: brightness(50%);
    z-index: -1;
  }
  
  .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #1c208b;
    border-radius: 20px;
    color: white;
    text-align: center;
    padding: 20px;
    z-index: 2;
  }
  
  .title {
    font-size: 36px;
    font-weight: bold;
  }
  
  .message {
    font-size: 20px;
  }