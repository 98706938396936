.main {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.main > div:first-child {
  width: 40%;
  position: relative;
  padding: 0 25px;
  display: flex;
  align-items: center;
}

.main > div:first-child > div {
  width: 225%;
  height: 150vh;
  position: absolute;
  transform: rotate(40deg);
  top: -68%;
  left: -115%;
  border-radius: 225%;
  background: var(--primary, #1c208b);
}

.main > div:first-child > div > div {
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 400px;
  position: absolute;
  top: calc(50% - 200px);
  right: 50px;
  transform: rotate(-40deg);
  background-color: #f5f3f5;
}

.main > div:first-child img {
  width: 75%;
  border-radius: 100%;
}

.main > div:last-child {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: calc(5px + 8vw);
  padding-left: calc(5px + 6vw);
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  overflow-y: scroll;
  scrollbar-width: none;
}

.form {
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form > div {
  margin: 5px 0;
}

.form > div:has(div) > div > div:first-child,
.main input {
  width: 100%;
  border-radius: 6px;
  background: rgba(30, 30, 30, 0.35);
  color: #1c208b;
  padding: 10px;
  border-color: transparent;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form img {
  filter: brightness(0) saturate(100%) invert(11%) sepia(81%) saturate(4328%)
    hue-rotate(241deg) brightness(75%) contrast(101%);
}

.form > div:has(div) > div > div:nth-child(2) {
  border: none;
  border-radius: 6px;
}

.form > div:has(div) > div > div:nth-child(2) > div {
  padding: 10px;
  border-color: transparent;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #a8a8a8;
  color: #1c208b;
}

.form > div:has(div) > div > div:nth-child(2) > :nth-child(even) {
  background: #929292;
}

.main h1 {
  color: var(--primary, #1c208b);
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.main h3 {
  color: var(--primary, #1c208b);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.main h4 {
  color: var(--accent, #302b27);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 10px 0;
}

.main h5 {
  color: var(--accent, #302b27);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.main p {
  color: #1c208b;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 3px;
}

.main button {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 10px;
  background: var(--primary, #1c208b);
  margin-top: 10px;
  padding: 10px 0;
  border: none;
  color: var(--accent, #f5f3f5);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.main button:hover {
  background: var(--primary, rgb(28, 31, 110));
}
.main button.loader2,
.main button.loader1 {
  width: 50px;
  min-height: 50px;
  margin: 38px auto 24px auto;
  cursor: default;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--primary, #1c208b) 94%, #0000)
      top/8px 8px no-repeat,
    conic-gradient(#0000 30%, var(--primary, #1c208b));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
}

.main button.loader1 {
  margin: 4px auto 2px auto;
}

@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}

.main button.google {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #b8b8b8;
  border-radius: 5px;
  color: #444;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.main button.google:hover {
  background-color: #f1f1f1;
}

.main button.google:active {
  background-color: #d4d4d4;
}

.main button.google > img {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.main button.google:focus {
  outline: none;
  border-color: #4285f4;
  /* Google's blue */
  box-shadow: 0 0 0 3px rgba(66, 133, 244, 0.3);
}

.main button + p,
.main button.loader2 + p {
  color: var(--accent, #302b27);
  font-weight: 500;
  text-align: center;
  margin-top: 5px;
}

.main a {
  color: var(--primary, #1c208b);
  text-decoration: underline;
}

.main input:focus {
  outline: none;
  border: var(--primary, #1c208b) 2px solid;
  background-color: #f1f1f1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: var(--primary, #1c208b);
  border: rgba(30, 30, 30, 0.35) 2px solid;
  box-shadow: inset 0 0 20px 20px rgba(30, 30, 30, 0.35);
}

.main input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #1c208b;
  font-family: Poppins, Arial, Helvetica, sans-serif;
}

.main input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #1c208b;
  opacity: 1;
  font-family: Poppins, Arial, Helvetica, sans-serif;
}

.main input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #1c208b;
  opacity: 1;
  font-family: Poppins, Arial, Helvetica, sans-serif;
}

.main input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #1c208b;
  font-family: Poppins, Arial, Helvetica, sans-serif;
}

.main input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #1c208b;
  font-family: Poppins, Arial, Helvetica, sans-serif;
}

.main input::placeholder {
  /* Most modern browsers support this now. */
  color: #1c208b;
  font-family: Poppins, Arial, Helvetica, sans-serif;
}

.otp {
  margin: 10px 0;
}

.otp > input {
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #f5f3f5;
  border-radius: 7px;
  border: 2px solid var(--primary, #1c208b);
  margin-right: 10px;
  padding: 5px;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
}

.condition {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 20px 0 10px;
  text-align: center;
}

.blue {
  color: #1c208b;
}

.back {
  position: absolute;
  top: 30px;
  right: 30px;
  color: var(--primary, #1c208b);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.password {
  display: flex;
  flex-wrap: nowrap;
}

.password > div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -36px;
  cursor: pointer;
  min-width: 36px;
  padding-right: 6px;
  opacity: 0.4;
  transition: all ease-out 200ms;
}

.password > div:hover {
  opacity: 1;
  transform: scale(1.2);
}

.error {
  position: relative;
  margin-bottom: 30px;
}

.error > div > div,
.error > input {
  border-color: rgb(247, 58, 58) !important;
}

.error h6 {
  position: absolute;
  bottom: -15px;
  display: block !important;
  color: rgb(247, 58, 58);
  font-weight: 500;
}

.space {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 576px) {
  .main {
    flex-direction: column;
    gap: 20px;
  }

  .main h1 {
    font-size: 40px;
  }

  .main > div {
    width: 100% !important;
  }

  .main > div:first-child {
    height: calc(22% + 5vh);
    position: relative;
    padding: 0;
  }

  .main > div:first-child img {
    margin-top: calc(50px - 5vh);
  }

  .main button + p,
  .main button.loader2 + p {
    text-wrap: balance;
  }

  .main > div:first-child > div {
    width: 100%;
    height: calc(160% + 10vh);
    position: relative;
    transform: rotate(0deg);
    top: -50%;
    left: 0;
    border-radius: 200%;
    background: var(--primary, #1c208b);
  }

  .main > div:first-child > div > div {
    width: calc(110px + 10vh);
    height: calc(110px + 10vh);
    border-radius: 200px;
    position: absolute;
    top: auto;
    left: calc(50% - 100px);
    right: auto;
    bottom: 20px;
    transform: rotate(0deg);
  }

  .main > div:last-child {
    padding: 0 40px;
    padding-top: 40px;
    padding-bottom: 25px;
    position: relative;
    overflow-y: scroll;
  }

  .form {
    padding: 0;
  }

  .form h3,
  .form h4,
  .form h5 {
    text-align: center;
  }

  .main button {
    margin: 20px;
    margin-bottom: 0;
    width: calc(100% - 40px);
  }

  .otp {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .otp > input {
    margin: 0;
  }

  .condition {
    font-size: 15px;
    line-height: normal;
    margin: 20px 0 30px;
    vertical-align: middle;
    text-align: start;
  }

  .back {
    top: auto;
    right: 0;
    left: 0;
    text-align: center;
    bottom: -30px;
    display: none;
  }
}
