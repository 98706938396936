.nav {
  background: var(--accent, #f5f3f5);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 0 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 31;
  top: 0;
}

.nav > img.desktopOnly {
  height: 45px;
  border-radius: 100%;
  border: solid 0.5px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.nav > img:first-of-type {
  height: 80px;
  border: none;
}

.nav li {
  display: block;
  padding: 12px 16px;
  list-style-type: none;
  text-align: center;
  border-radius: 10px;
}
.nav ul {
  position: relative;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: rgb(28, 32, 139);
  min-width: 100px;
  z-index: 1;
}
.nav li:hover {
  background-color: rgb(28, 32, 100);
}
.nav a {
  text-decoration: none;
  color: #f5f3f5;
}
.dropdown {
  position: absolute;
  top: 55px;
  right: 50px;
}
.links {
  display: flex;
  gap: 25px;
}

.links > div {
  padding: 5px 3px;
}

.links a {
  color: var(--accent, #302b27);
  font-size: calc(20px - 0.1vw);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 12.5px;
}

.active {
  border-bottom: solid 2px #1c208b;
}

.active a {
  color: #1c208b;
}

.mobileOnly {
  display: none;
}

.buttons {
  gap: 5px;
}

.buttons button {
  border-radius: 20px;
  background: linear-gradient(
    123deg,
    rgba(245, 243, 245, 1) 0%,
    rgba(245, 243, 245, 0.85) 100%
  );
  border: 1px solid #1c208b;
  color: #1c208b;
  font-size: 18px;
  font-weight: 700;
  padding: 5px 20px;
  cursor: pointer;
}

.buttons button:hover {
  background: linear-gradient(
    123deg,
    rgba(225, 223, 225, 1) 0%,
    rgba(225, 223, 225, 0.85) 100%
  );
}
.buttons button + button {
  margin-left: 10px;
}

.buttons button:last-of-type {
  background: linear-gradient(
    123deg,
    rgba(28, 32, 139, 1) 0%,
    rgba(28, 32, 139, 0.85) 100%
  );
  color: var(--accent, #f5f3f5);
}

.buttons button:last-of-type:hover {
  background: linear-gradient(
    123deg,
    rgba(8, 12, 119, 1) 0%,
    rgba(8, 12, 119, 0.85) 100%
  );
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 992px) {
}

/* @media only screen and (max-width: 768px) {
    
} */

@media only screen and (max-width: 870px) {
  .nav {
    /* flex-direction: row-reverse; */
    padding: 20px 30px;
  }

  .nav > img:nth-of-type(2) {
    height: 40px !important;
  }

  img.mobileOnly {
    height: 30px;
  }

  .nav > img:last-of-type {
    filter: brightness(0) saturate(100%) invert(11%) sepia(81%) saturate(4328%)
      hue-rotate(241deg) brightness(75%) contrast(101%);
  }

  .desktopOnly {
    display: none;
  }

  .mobileOnly {
    display: block;
  }

  .links.mobileOnly {
    position: absolute;
    width: 100vw;
    height: 100vh;
    padding-bottom: 50px;
    z-index: 32;
    top: 0;
    left: 0;
    background: var(--accent, #f5f3f5);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    overflow: scroll;
  }

  .links.mobileOnly > div {
    display: flex;
    flex-direction: column;
    gap: calc(10px + 0.5vh);
    width: 100%;
    padding: 0 35px;
  }

  .links.mobileOnly > img {
    height: 100px;
  }

  .links.mobileOnly > img:first-of-type {
    height: 45px;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .links.mobileOnly a {
    border-radius: 7px;
    background: var(--primary, #1c208b);
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: var(--accent, #f5f3f5);
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .buttons button {
    font-size: 13.5px;
    padding: 10px 15px;
  }

  .profile {
    width: 110vw !important;
    background-color: #1c208b;
    border-radius: 0% 0% 100% 100% / 100% 100% 50% 50%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px !important;
  }

  .profile img {
    margin-top: 10px;
    height: 100px;
    width: 100px;
    border-radius: 100%;
    box-shadow: -1px -1px 2px 0px rgba(253, 255, 253, 0.25),
      1px 1px 2px 0px rgba(253, 255, 253, 0.25);
  }
  .profile h2 {
    padding-bottom: 8px;
    font-size: 20px;
  }
}
